import {useState, useEffect} from 'react';

interface AsyncActionProps {
    action?: () => ((dispatch: any) => Promise<any> | undefined) | undefined,
    render: ({run, loading}: { run: () => void; loading: boolean }) => JSX.Element;
}

const AsyncAction = ({action, render}: AsyncActionProps) => {
    const [loading, setLoading] = useState(false);
    let canceled = false;

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            canceled = true;
        };
    }, []);

    const run = () => {
        if (loading || !action) {
            return;
        }

        setLoading(true);
        //@ts-ignore
        action()?.then(() => {
            if (canceled) {
                return;
            }

            setLoading(false);
        });
    };

    return render({run, loading});
};

export default AsyncAction;
