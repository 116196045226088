import React from 'react'
import classNames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import Link from 'next/link'
import AsyncAction from '../shared/AsyncAction'
import {Cart16Svg} from '../../svg'
import {cartAddItem, getUpOrCrossProd} from '../../store/cart'
import {url} from '../../services/utils'
import Currency from '../shared/Currency'
import defaultPicture from '../../images/defaultpic.webp'
import {FormattedMessage} from 'react-intl'
import {setCrossValid, setPopupName, setTempData,} from "../../store/general";
import {IState} from "../../types/state";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {domainUrl} from '../../helper'
import InStockProducts from "../shared/InStockProducts";
import {useRouter} from "next/router";

//@ts-ignore
function Suggestions(props) {
    const {context, className, products} = props
    const rootClasses = classNames(
        `suggestions suggestions--location--${context}`,
        className,
    )
    const router = useRouter();
    const dispatch = useDispatch();
    const cartToken = useSelector((state: IState) => state.cartToken);
    const customer = useSelector((state: IState) => state.customer)
    const backorders = useSelector((state: IState) => state.general.Backorders);
    const outOfStock = Boolean(+useSelector((state: IState) => state.general.coreConfigs.catalog_products_homepage_out_of_stock_items?.value))
    const rate = useSelector((state: IState) => state.rate.exchange_rate.rate);
    const selectedData = useSelector((state: IState) => state.locale.code)
    const currency = useSelector((state: IState) => state.rate.current.code)

    //@ts-ignore
    const openUpCrossProd = (product) => {
        let hasUpSell = product?.product_flat[0].has_up_sell == 1;
        let hasCrossSell = product?.product_flat[0].has_cross_sell == 1;
        let page = true;

        if (!hasUpSell && hasCrossSell) {
            dispatch(getUpOrCrossProd(product.id, 'crossSell', selectedData, currency, rate, page))
            dispatch(setPopupName("crossSell"));
        }
        if (hasUpSell) {
            dispatch(setPopupName("upsell"));
            dispatch(getUpOrCrossProd(product.id, 'upSell', selectedData, currency, rate, page))
        }
    };

    const isProductInStock = (product: { product_inventories: { qty: any; }[]; }) => {
        let qty = product?.product_inventories[0]?.qty
        return (qty > 0 || qty === 0 && backorders)
    }
    let skuName;
    const list =
        products &&
        //@ts-ignore
        products.map((product) => {
                return product.id != -1 ?
                    //@ts-ignore
                    <InStockProducts
                        qty={product?.product_inventories[0]?.qty}
                        stockID="no_matching_items"
                        stockValue="No matching items"
                        key={product?.product_flat[0]?.product_id}
                    >
                        <li className="suggestions__item">
                            {product?.product_images &&
                            product?.product_images?.length > 0 && (
                                <div className="suggestions__item-image product-image">
                                    <div className="search-product-image__body-fms 1">
                                        <LazyLoadImage
                                            src={domainUrl(`${props.dbName}/storage/${product?.product_images[0]?.path}`)}
                                            alt={product?.product_flat[0]?.name}
                                            width={50}
                                            height={"auto"}
                                        />
                                    </div>
                                </div>
                            ) ? (
                                <div className="suggestions__item-image product-image">
                                    <div className="search-product-image__body-fms 2">
                                        <Link href={url.product(product)}>
                                            <a>
                                                <LazyLoadImage
                                                    src={domainUrl(`${props.dbName}/storage/${product?.product_images[0]?.path}`)}
                                                    alt={product?.product_flat[0]?.name}
                                                    width={50}
                                                    height={"auto"}
                                                />
                                            </a>

                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <div className="suggestions__item-image product-image">
                                    <div className="search-product-image__body-fms 3">
                                        <LazyLoadImage
                                            //@ts-ignore
                                            src={defaultPicture.src}
                                            alt={product?.product_flat[0]?.name}
                                            width={50}
                                            height={"auto"}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="suggestions__item-info">
                                <>
                                    <Link
                                        className="suggestions__item-name"
                                        href={url.product(product)}
                                    >
                                        <a> {product?.product_flat[0]?.name}</a>

                                    </Link>
                                    <div
                                        //@ts-ignore
                                        className="suggestions__item-meta">{product?.sku_option[0]?.translations?.find(el => el.locale === selectedData)?.name}: {product?.product_flat[0]?.sku}</div>
                                </>
                            </div>
                            <div className="suggestions__item-price">
                                <Currency
                                    value={
                                        product.special_price
                                            ? product.special_price
                                            : product.price ||
                                            Number(product?.min_price).toFixed(2)
                                    }
                                />
                                {' '}
                            </div>
                            {context === 'header' && (
                                <div className={classNames("suggestions__item-actions",
                                    {
                                        "button_disabled": product?.product_inventories[0]?.qty === 0
                                            //@ts-ignore
                                            && backorders == 0
                                            //@ts-ignore
                                            && !outOfStock
                                    })}>

                                    {
                                        (product && product?.type === "configurable") ||
                                        product?.type === "bundle" ||
                                        product.product_flat[0].min_qty
                                            ? (
                                                <Link
                                                    href={{
                                                        // @ts-ignore
                                                        pathname: `${url.product(product)}`,
                                                        query: {...router.query},
                                                    }}
                                                >
                                                    <a>
                                                        <button
                                                            type="button"
                                                            className={classNames(
                                                                'btn btn-primary btn-sm btn-svg-icon suggestion-btn',
                                                                {
                                                                    // 'btn-loading': loading,
                                                                },
                                                            )}
                                                            disabled={!backorders && !isProductInStock(product)}
                                                        >
                                                            <Cart16Svg/>
                                                        </button>
                                                    </a>

                                                </Link>
                                            )
                                            : product.product_flat[0].has_up_sell == 0 &&
                                            product.product_flat[0].has_cross_sell == 0
                                                ? (
                                                    <AsyncAction
                                                        //@ts-ignore
                                                        action={() =>
                                                            dispatch(cartAddItem(
                                                                product,
                                                                [],
                                                                1,
                                                                cartToken,
                                                                customer,
                                                                selectedData,
                                                                null,
                                                                "homePage"
                                                            ))
                                                        }
                                                        //@ts-ignore
                                                        render={({run, loading}) => (
                                                            <button
                                                                type="button"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    dispatch(setCrossValid(false));
                                                                    run();
                                                                }}
                                                                className={classNames(
                                                                    'btn btn-primary btn-sm btn-svg-icon suggestion-btn',
                                                                    {
                                                                        'btn-loading': loading,
                                                                    },
                                                                )}
                                                                disabled={!backorders && !isProductInStock(product)}
                                                            >
                                                                <Cart16Svg/>
                                                            </button>
                                                        )}
                                                    />
                                                ) : product.product_flat[0].has_up_sell == 0 && product.product_flat[0].has_cross_sell == 1 ? (
                                                    <AsyncAction
                                                        //@ts-ignore
                                                        action={() =>
                                                            dispatch(cartAddItem(
                                                                product,
                                                                [],
                                                                1,
                                                                cartToken,
                                                                customer,
                                                                selectedData,
                                                                null,
                                                                "homePage"
                                                            ))
                                                        }
                                                        //@ts-ignore
                                                        render={({run, loading}) => (
                                                            <button
                                                                type="button"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    run();
                                                                    dispatch(setTempData([product]));
                                                                    dispatch(setCrossValid(true));
                                                                    openUpCrossProd(product);
                                                                }}
                                                                className={classNames(
                                                                    'btn btn-primary btn-sm btn-svg-icon suggestion-btn',
                                                                    {
                                                                        'btn-loading': loading,
                                                                    },
                                                                )}
                                                                disabled={!backorders && !isProductInStock(product)}
                                                            >
                                                                <Cart16Svg/>
                                                            </button>
                                                        )}
                                                    />
                                                ) : (
                                                    <AsyncAction
                                                        //@ts-ignore
                                                        render={({run, loading}) => (
                                                            <button
                                                                type="button"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    run();
                                                                    dispatch(setTempData([product]));
                                                                    dispatch(setCrossValid(false));
                                                                    openUpCrossProd(product);
                                                                }}
                                                                className={classNames(
                                                                    'btn btn-primary btn-sm btn-svg-icon suggestion-btn',
                                                                    {
                                                                        'btn-loading': loading,
                                                                    },
                                                                )}
                                                                disabled={!backorders && !isProductInStock(product)}
                                                            >
                                                                <Cart16Svg/>
                                                            </button>
                                                        )}
                                                    />
                                                )
                                    }
                                </div>
                            )}
                        </li>
                    </InStockProducts>
                    : <div className="search__fm_dr_Message">
                        <FormattedMessage
                            id="no_matching_items"
                            defaultMessage="No matching items"
                        />
                    </div>
            }
        )
    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    )
}

export default Suggestions